import React, { useState, useEffect, useRef } from "react";
import { Navbar, Nav, Container, Button } from "react-bootstrap";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  NavLink,
} from "react-router-dom";
import HomeScreen from "./screens/HomeScreen";
import ContactScreen from "./screens/ContactScreen";
import ServicesScreen from "./screens/ServicesScreen";
import AboutScreen from "./screens/AboutScreen";
import PortfolioScreen from "./screens/PortfolioScreen";
import PortfolioItem from "./screens/PortfolioItem";
import Footer from "./screens/Footer";

import ParticlesComponent from "./Particles";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./styles/Navbar.css";
import "./styles/Buttons.css";
import "./styles/Services.css";
import "./styles/Contact.css";
import "./styles/About.css";
import "./styles/Portfolio.css";
import "./styles/PageAnimations.css";

function NavbarComponent() {
  const [isSticky, setIsSticky] = useState(false);

  const [placeholderHeight, setPlaceholderHeight] = useState(0);
  const navbarRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const navbarHeight = navbarRef.current?.offsetHeight || 0;
      const brandContainerHeight =
        document.querySelector(".brand-container")?.offsetHeight || 0;
      const stickyThreshold = brandContainerHeight;
      const shouldStick = window.scrollY > stickyThreshold;

      if (isSticky !== shouldStick) {
        setIsSticky(shouldStick);
        // navbar placehold height (fixes bug where navbar snaps up and down when sticky is applied)
        setPlaceholderHeight(shouldStick ? navbarHeight : 0);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isSticky]);

  return (
    <>
      <div style={{ height: `${placeholderHeight}px` }}></div>

      <Navbar
        ref={navbarRef}
        collapseOnSelect
        expand="lg"
        variant="dark"
        className={`custom-navbar ${isSticky ? "sticky-navbar" : ""}`}
      >
        <Container>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive ? "nav-link active" : "nav-link"
                }
                end
              >
                Home
              </NavLink>

              <NavLink
                to="/services"
                className={({ isActive }) =>
                  isActive ? "nav-link active" : "nav-link"
                }
              >
                Services
              </NavLink>
              <NavLink
                to="/portfolio"
                className={({ isActive }) =>
                  isActive ? "nav-link active" : "nav-link"
                }
              >
                Portfolio
              </NavLink>
              {/* <NavLink
                to="/about"
                className={({ isActive }) =>
                  isActive ? "nav-link active" : "nav-link"
                }
              >
                About
              </NavLink> */}
            </Nav>
            <NavLink
              to="/contact"
              className={({ isActive }) =>
                isActive
                  ? "nav-btn-rect-to-round nav-btn-rect-to-round--white nav-btn-rect-to-round--active"
                  : "nav-btn-rect-to-round nav-btn-rect-to-round--white"
              }
            >
              Contact
            </NavLink>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

function App() {
  return (
    <Router>
      <div>
        <ParticlesComponent id="particles" />
        <div className="brand-container">
          <h1>Digital Lift Consulting</h1>
          <p>ELEVATE YOUR BUSINESS</p>
        </div>

        <NavbarComponent />

        <div>
          <Routes>
            <Route path="/services" element={<ServicesScreen />} />
            <Route path="/portfolio" element={<PortfolioScreen />} />
            <Route path="/portfolio/:id" element={<PortfolioItem />} />
            <Route path="/contact" element={<ContactScreen />} />
            <Route path="/" element={<HomeScreen />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
