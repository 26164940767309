import React from "react";
import { Button } from "react-bootstrap";

import { Container, Col, Row, Card } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCogs,
  faCode,
  faLaptopCode,
  faChartLine,
  faUsers,
  faBullhorn,
  faGear,
} from "@fortawesome/free-solid-svg-icons";

function OurServices({ animate, handleServiceNavigate }) {
  const DisplayContent = (active) => {
    for (let i = 0; i < 3; i++) {
      if (i === active) {
        document.getElementById(`s${i}`).classList.add("btn-active");
        continue;
      }
      document.getElementById(`s${i}`).classList.remove("btn-active");
    }
    for (let i = 0; i < 3; i++) {
      if (i === active) {
        //Unset hidden
        document.getElementById(`step${i}`).hidden = false;
        continue;
      }
      document.getElementById(`step${i}`).hidden = true;
    }
  };

  return (
    <Container>
      <Row>
        <Col md={12} className="mb-3">
          <Card
            className={`primary-container ${
              animate ? "card-animate-pop delay-6" : "hidden-initially"
            }`}
          >
            <Card.Body>
              <Card.Title style={{ textAlign: "center" }}>
                <h1>Why call us?</h1>
                <div id="outter-divider"></div>
              </Card.Title>
              <nav className="nav nav-pills nav-fill justify-content-evenly text-center">
                <Row className="icon-text d-flex">
                  <Col>
                    <div
                      id="s0"
                      className="our-services-button"
                      onClick={() => DisplayContent(0)}
                    >
                      <h5>1</h5>
                    </div>
                  </Col>
                  <Col>
                    <div
                      id="s1"
                      className="our-services-button"
                      onClick={() => DisplayContent(1)}
                    >
                      <h5>2</h5>
                    </div>
                  </Col>
                  <Col>
                    <div
                      id="s2"
                      className="our-services-button"
                      onClick={() => DisplayContent(2)}
                    >
                      <h5>3</h5>
                    </div>
                  </Col>
                </Row>
              </nav>

              <div id="step0" className="active-step" hidden={false}>
                <div id="service-0" className="service-content">
                  <h5 className="text-center">"I've Got an Idea!" - You</h5>
                  <p>
                    At <strong>Digital Lift Consulting</strong>, we specialize
                    in transforming your innovative concepts into functional,
                    thriving digital solutions. Whether you envision a sleek web
                    application or a dynamic mobile app, our expert team is here
                    to bring your ideas to life.
                  </p>

                  <p>
                    We offer comprehensive{" "}
                    <strong>full-stack development services</strong>
                    tailored to your unique business needs. Our capabilities
                    span from crafting single-page websites to developing
                    complex, cross-platform applications.
                  </p>

                  <h4>Our Process:</h4>
                  <ul>
                    <li>
                      <strong>Idea Validation:</strong> We start by evaluating
                      your idea's feasibility and uniqueness. Why build
                      something that already exists? We ensure your vision is
                      both original and viable.
                    </li>
                    <li>
                      <strong>Custom Development:</strong> Once validated, our
                      skilled developers and designers will create a bespoke
                      solution that aligns perfectly with your goals.
                    </li>
                    <li>
                      <strong>Seamless Collaboration:</strong> Throughout the
                      development journey, we work closely with you to refine
                      and perfect every detail.
                    </li>
                  </ul>
                  <p>
                    Ready to turn your idea into a reality? Let's collaborate
                    and create something extraordinary!
                  </p>

                  <p>Get Started Today!</p>
                </div>
              </div>
              <div id="step1" className="active-step" hidden={true}>
                <div id="service-1" className="service-content">
                  <h5 className="text-center">
                    "We're Struggling to Keep Up with the Work" - You
                  </h5>

                  <p>
                    At <strong>Digital Lift Consulting</strong>, we understand
                    the challenges of managing technology demands while keeping
                    your business on track. Whether you're overwhelmed with the
                    pace of innovation or need strategic guidance, our team is
                    here to help you thrive.
                  </p>

                  <h4>What We Offer:</h4>
                  <ul class="cta-list">
                    <li>
                      <strong>Expert Training:</strong> Equip your team with the
                      latest skills and knowledge through our comprehensive
                      training programs, designed to keep you ahead in a
                      fast-paced tech landscape.
                    </li>
                    <li>
                      <strong>CTO Services:</strong> Need strategic leadership?
                      Our experienced Chief Technology Officers provide expert
                      guidance to align your technology with your business
                      goals, ensuring you stay competitive and agile.
                    </li>
                  </ul>

                  <h4>Our Approach:</h4>
                  <ul class="cta-list">
                    <li>
                      <strong>Assessment & Strategy:</strong> We begin with a
                      thorough assessment of your current capabilities and
                      challenges. We then craft a tailored strategy to address
                      your specific needs and opportunities.
                    </li>
                    <li>
                      <strong>Hands-on Implementation:</strong> Our team not
                      only advises but also works directly with you to implement
                      effective solutions. From upskilling your team to steering
                      your technology direction, we’ve got you covered.
                    </li>
                    <li>
                      <strong>Ongoing Support:</strong> Beyond initial
                      implementation, we offer continuous support to adapt and
                      optimize your technology as your business evolves.
                    </li>
                  </ul>

                  <p>
                    <strong>Partner with Digital Lift Consulting</strong> and
                    regain control over your technology. Whether you need to
                    upskill your team or require executive-level technology
                    leadership, we're here to lift your business to new heights.
                  </p>
                </div>
              </div>
              <div id="step2" className="active-step" hidden={true}>
                <div id="service-2" className="service-content">
                  <h5 className="text-center">
                    "We Need a Strong Online Presence" - You
                  </h5>

                  <p>
                    In today's digital landscape, a compelling website and a
                    strong SEO strategy are crucial for success. At{" "}
                    <strong>Digital Lift Consulting</strong>, we specialize in
                    creating powerful web solutions and optimizing your online
                    presence to ensure your business stands out and reaches its
                    full potential.
                  </p>

                  <h4>Web Development Services:</h4>
                  <ul class="cta-list">
                    <li>
                      <strong>Custom Website Design & Development:</strong> Our
                      team crafts bespoke websites that not only look stunning
                      but also provide a seamless user experience. We build
                      responsive, fast, and scalable web applications tailored
                      to your business needs.
                    </li>
                    <li>
                      <strong>E-commerce Solutions:</strong> Ready to sell
                      online? We develop robust e-commerce platforms that
                      enhance your customers' shopping experience and drive your
                      sales.
                    </li>
                    <li>
                      <strong>Maintenance & Support:</strong> Keeping your
                      website updated and running smoothly is our priority. We
                      offer ongoing maintenance and support to ensure your site
                      remains secure and functional.
                    </li>
                  </ul>

                  <h4>Search Engine Optimization (SEO) Services:</h4>
                  <ul class="cta-list">
                    <li>
                      <strong>Comprehensive SEO Audit:</strong> We start with a
                      deep dive into your current online performance,
                      identifying strengths and areas for improvement.
                    </li>
                    <li>
                      <strong>Keyword Research & Strategy:</strong> Our SEO
                      experts conduct thorough keyword research to find the best
                      opportunities for your business to rank higher in search
                      engine results.
                    </li>
                    <li>
                      <strong>On-Page Optimization:</strong> We optimize your
                      website’s content, structure, and metadata to ensure
                      search engines understand and prioritize your site.
                    </li>
                    <li>
                      <strong>Content Creation & Marketing:</strong> Engaging
                      content is key to attracting and retaining visitors. We
                      create high-quality content that resonates with your
                      audience and boosts your search rankings.
                    </li>
                    <li>
                      <strong>Link Building & Outreach:</strong> We enhance your
                      site's authority and visibility through strategic link
                      building and outreach efforts.
                    </li>
                  </ul>

                  <h4>Why Choose Us?</h4>
                  <ul class="cta-list">
                    <li>
                      <strong>Customized Solutions:</strong> We tailor our web
                      development and SEO strategies to align with your specific
                      goals and target audience.
                    </li>
                    <li>
                      <strong>Proven Expertise:</strong> With years of
                      experience in the industry, our team has the knowledge and
                      skills to deliver exceptional results.
                    </li>
                    <li>
                      <strong>Continuous Improvement:</strong> The digital world
                      is ever-evolving, and so are our techniques. We stay ahead
                      of the latest trends and technologies to keep your
                      business competitive.
                    </li>
                  </ul>

                  <p>
                    <strong>Ready to Elevate Your Online Presence?</strong>{" "}
                    Let’s build a captivating website and implement an SEO
                    strategy that drives traffic and converts visitors into
                    loyal customers.
                  </p>
                </div>
              </div>

              <div style={{ textAlign: "center" }}>
                <button
                  className="btn btn-rect-to-round btn-rect-to-round--blue"
                  onClick={handleServiceNavigate}
                >
                  Our Services
                </button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default OurServices;

{
  /* <div>
<Row className="icon-text mb-3">
    <Col md={1}>
        <FontAwesomeIcon icon={faCogs} size="lg" />
    </Col>
    <Col md={3}>
        <h5>Consultation & Development</h5>
    </Col>
    <Col md={8}>
        <p>After your free consultation, Digital Lift's software development team will collaborate with you to build the solution that your business needs.</p>
    </Col>
</Row>

<Row className="icon-text mb-3">
    <Col md={1}>
        <FontAwesomeIcon icon={faCode} size="lg" />
    </Col>
    <Col md={3}>
        <h5>Custom Software Solutions</h5>
    </Col>
    <Col md={8}>
        <p>Our wide-ranging skillset will give you exactly the tools you're looking for— from a basic landing webpage to a fully integrated mobile app.</p>
    </Col>
</Row>

<Row className="icon-text mb-3">
    <Col md={1}>
        <FontAwesomeIcon icon={faLaptopCode} size="lg" />
    </Col>
    <Col md={3}>
        <h5>Training & Support</h5>
    </Col>
    <Col md={8}>
        <p>Digital Lift offers fast-acting support, and we will train your team to efficiently benefit from the tools we provide you.</p>
    </Col>
</Row>

<Row className="icon-text mb-3">
    <Col md={1}>
        <FontAwesomeIcon icon={faChartLine} size="lg" />
    </Col>
    <Col md={3}>
        <h5>Digital Marketing & Analytics</h5>
    </Col>
    <Col md={8}>
        <p>We know how to drive traffic to your business. Let us optimise your web presence and use our suite of analytic tools to convert impressions to sales.</p>
    </Col>
</Row>

<Row className="icon-text mb-3">
    <Col md={1}>
        <FontAwesomeIcon icon={faUsers} size="lg" />
    </Col>
    <Col md={3}>
        <h5>Subscription Services</h5>
    </Col>
    <Col md={8}>
        <p>Focus on running your operations. With our subscription service, we'll keep your website live, updated, and growing.</p>
    </Col>
</Row>
</div> */
}
