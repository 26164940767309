import React, { useState, useEffect } from 'react';
import { Container, Col, Row, Card, Form, FormGroup, FormLabel, FormControl, Button } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';


const ContactScreen = () => {

    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        setAnimate(true);
    }, []);

    const sendEmail = (e) => {
        e.preventDefault();
        //THIS IS WHERE THE EMAILJS STUFF GOES ONCE JUSTIN MAKES ACCOUNT FOR IT

    };

    const redirectEmail = () => {
        window.location.href = 'mailto:info@digitallift.com';
    };

    const redirectPhone = () => {
        window.location.href = 'tel:+12894569065';
    };

    return (
        <div>
            <h1 className={`text-center  page-title ${animate ? 'heading-text-animation delay-1' : 'hidden-initially'}`}>Contact Us</h1>
            <div id="outter-divider" className={` ${animate ? 'heading-line-animation delay-2' : 'hidden-initially'}`}></div>

            <Container>
                <Card className={`form-card ${animate ? 'card-animate-pop delay-3' : 'hidden-initially'}`}>
                    <Card.Body>
                        <Form onSubmit={sendEmail}>
                            <FormGroup className='form-element'>
                                <FormLabel>Name</FormLabel>
                                <FormControl type="text" name="user_name" />
                            </FormGroup>
                            <FormGroup className='form-element'>
                                <FormLabel>Email</FormLabel>
                                <FormControl type="email" name="user_email" />
                            </FormGroup>
                            <FormGroup className='form-element'>
                                <FormLabel>Subject</FormLabel>
                                <FormControl type="text" name="subject" />
                            </FormGroup>
                            <FormGroup className='form-element'>
                                <FormLabel>Message</FormLabel>
                                <FormControl className="contact-text-area" as="textarea" name="message" rows={6} />
                            </FormGroup>
                            <br></br>
                            <Button className="btn send-btn-rect-to-round--blue" type="submit" variant="primary">Send</Button>
                        </Form>
                    </Card.Body>
                </Card>
            </Container>

            <Container>
                <h2 style={{ textAlign: 'center', fontSize: 40, marginTop: 25 }} className={`${animate ? 'heading-text-animation delay-4' : 'hidden-initially'}`}>Get in touch directly</h2>

                <Row>
                    <Col md={6} className="mb-4">
                        <Card className={`contact-card clickable ${animate ? 'card-animate-pop delay-4' : 'hidden-initially'}`} onClick={redirectPhone}>
                            <FontAwesomeIcon className='contact-card-icon' icon={faPhone} size="2xl" />
                            <h3>(289) 456-9065</h3>
                        </Card>
                    </Col>
                    <Col md={6} className="mb-4">
                        <Card className={`contact-card clickable ${animate ? 'card-animate-pop delay-5' : 'hidden-initially'}`} onClick={redirectEmail}>
                            <FontAwesomeIcon className='contact-card-icon' icon={faEnvelope} size="2xl" />
                            <h3>info@digitallift.com</h3>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ContactScreen;