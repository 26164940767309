import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Col, Row, Card, Image } from "react-bootstrap";
import OurServices from "../components/OurServices";
import logo from "../assets/Gif/digital_Lift_consulting_animated.gif";

const HomeScreen = () => {
  const navigate = useNavigate();

  const handleServiceNavigate = () => {
    navigate("/services");
  };
  const handleContactNavigate = () => {
    navigate("/contact");
  };

  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(true);
  }, []);

  return (
    <div style={{ marginTop: 30 }}>
      <div className="landing-page">
        <Container>
          <Row className="align-items-center">
            <Col md={8} className="mb-3">
              <h1
                className={` ${
                  animate ? "slide-in-animation delay-1" : "hidden-initially"
                }`}
              >
                Simplify Tasks, Amplify Results
              </h1>
              <h3
                className={` ${
                  animate ? "slide-in-animation delay-2" : "hidden-initially"
                }`}
              >
                Experts in developing efficient business processes through the
                use of modern technologies.
              </h3>
            </Col>
            <Col
              md={4}
              className="d-flex justify-content-center align-items-center"
            >
              <Image
                src={logo}
                style={{ width: "80%" }}
                alt="Digital Lift Consulting"
                className="img-fluid"
              />
            </Col>
          </Row>
        </Container>
      </div>

      <OurServices
        animate={animate}
        handleServiceNavigate={handleServiceNavigate}
      />

      <br></br>
      <br></br>

      <Container className="mt-5">
        <h1 style={{ textAlign: "center" }}>Client Testimonials</h1>
        <div
          id="outter-divider"
          className={` ${
            animate ? "heading-line-animation delay-7" : "hidden-initially"
          }`}
        ></div>
        <br></br>

        <Row>
          <Col lg={4} className="mb-3">
            <Card className="testimonial-card">
              <Card.Body>
                <Card.Title>
                  Terry Watson - The Head of the Lake Historical Society
                </Card.Title>
                <Card.Text>
                  "We are extremely pleased with our new site and are proud to
                  display the signature of Digital Lift Consulting at the bottom
                  of our page. The Head-of-the-Lake Historical Society would not
                  hesitate to recommend Justin Donaldson to any organization
                  with similar objectives."
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4} className="mb-3">
            <Card className="testimonial-card">
              <Card.Body>
                <Card.Title>Andrew Dixon - PrivateAutoParts.com</Card.Title>
                <Card.Text>
                  "I recently had the pleasure of working with Justin from
                  Digital Lift Consulting on a website I had imagined years ago.
                  He was able to take all the details and goals I set out, and
                  blew away my expectations."
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4} className="mb-3">
            <Card className="testimonial-card">
              <Card.Body>
                <Card.Title>Peter Pusitz - Results Lifestyle</Card.Title>
                <Card.Text>
                  "Our collaboration with Digital Lift Consulting on our custom
                  ERP application has been transformational for our business.
                  Their team expertly navigated our specific needs and delivered
                  a solution that integrates seamlessly with our operations. We
                  are grateful for their service and highly recommend them for
                  any enterprise looking to improve their administrative
                  processes and reduce costs."
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <br></br>
      <br></br>

      <Container style={{ textAlign: "center" }}>
        <h1>Ready to get online?</h1>
        <br></br>

        <div style={{ textAlign: "center" }}>
          <button
            className="btn btn-rect-to-round btn-rect-to-round--blue"
            onClick={handleContactNavigate}
          >
            Contact Us
          </button>
        </div>
      </Container>

      <br></br>
      <br></br>
    </div>
  );
};

export default HomeScreen;
