import React, { useState, useEffect } from 'react';

import { Link, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card } from 'react-bootstrap';

import portfolioItems from '../PortfolioData';

function PortfolioScreen() {
    const navigate = useNavigate();

    const handleCardClick = (id) => {
        navigate(`/portfolio/${id}`);
    };

    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        setAnimate(true);
    }, []);

    return (
        <div>
            <Container>
                <h1 className={`text-center  page-title ${animate ? 'heading-text-animation delay-1' : 'hidden-initially'}`}>Recent Projects</h1>
                <h4 className={`text-white text-center ${animate ? 'heading-text-animation delay-2' : 'hidden-initially'}`}>Check out some of our recent work!</h4>

                <div id="outter-divider" className={` ${animate ? 'heading-line-animation delay-3' : 'hidden-initially'}`}></div>


                <Row xs={1} md={2} className="g-4">
                    {portfolioItems.map(item => (
                        <Col key={item.id} xs={12} md={6}>
                            <Card className={`property-card ${animate ? 'card-animate-pop delay-4' : 'hidden-initially'}`} onClick={() => handleCardClick(item.id)} style={{ cursor: 'pointer' }}>
                                <Card.Img variant="top" src={item.image} className="property-image" />
                                <Card.Body className="property-description">
                                    <Card.Title className="property-title">{item.title}</Card.Title>
                                    <Card.Text>
                                        {item.description}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
        </div>
    );
}


export default PortfolioScreen;